/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1LaunchpadServiceType } from './V1LaunchpadServiceType';
import {
    V1LaunchpadServiceTypeFromJSON,
    V1LaunchpadServiceTypeFromJSONTyped,
    V1LaunchpadServiceTypeToJSON,
} from './V1LaunchpadServiceType';

/**
 * 
 * @export
 * @interface V1LaunchpadSection
 */
export interface V1LaunchpadSection {
    /**
     * 
     * @type {Array<V1LaunchpadServiceType>}
     * @memberof V1LaunchpadSection
     */
    visibleServiceTypes: Array<V1LaunchpadServiceType>;
}

/**
 * Check if a given object implements the V1LaunchpadSection interface.
 */
export function instanceOfV1LaunchpadSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "visibleServiceTypes" in value;

    return isInstance;
}

export function V1LaunchpadSectionFromJSON(json: any): V1LaunchpadSection {
    return V1LaunchpadSectionFromJSONTyped(json, false);
}

export function V1LaunchpadSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1LaunchpadSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visibleServiceTypes': ((json['visible_service_types'] as Array<any>).map(V1LaunchpadServiceTypeFromJSON)),
    };
}

export function V1LaunchpadSectionToJSON(value?: V1LaunchpadSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'visible_service_types': ((value.visibleServiceTypes as Array<any>).map(V1LaunchpadServiceTypeToJSON)),
    };
}

