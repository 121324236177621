/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const V1LaunchpadServiceType = {
    Unspecified: 'LAUNCHPAD_SERVICE_TYPE_UNSPECIFIED',
    Pickup: 'LAUNCHPAD_SERVICE_TYPE_PICKUP'
} as const;
export type V1LaunchpadServiceType = typeof V1LaunchpadServiceType[keyof typeof V1LaunchpadServiceType];


export function V1LaunchpadServiceTypeFromJSON(json: any): V1LaunchpadServiceType {
    return V1LaunchpadServiceTypeFromJSONTyped(json, false);
}

export function V1LaunchpadServiceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1LaunchpadServiceType {
    return json as V1LaunchpadServiceType;
}

export function V1LaunchpadServiceTypeToJSON(value?: V1LaunchpadServiceType | null): any {
    return value as any;
}

