import { z } from 'zod'

import { stringBooleanTransform } from './transforms'

export const stringBooleanSchema = z
  .boolean()
  .or(z.string())
  .transform(stringBooleanTransform)
  .refine((value) => typeof value === 'boolean', {
    message: 'Value must be boolean',
  })
