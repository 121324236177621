/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const V1SectionViewType = {
    Unspecified: 'SECTION_VIEW_TYPE_UNSPECIFIED',
    LocalStorySection: 'SECTION_VIEW_TYPE_LOCAL_STORY_SECTION',
    RecommendationBusinessPostSection: 'SECTION_VIEW_TYPE_RECOMMENDATION_BUSINESS_POST_SECTION',
    PublicInstitutionBusinessPostSection: 'SECTION_VIEW_TYPE_PUBLIC_INSTITUTION_BUSINESS_POST_SECTION',
    PopularCommerceCouponSection: 'SECTION_VIEW_TYPE_POPULAR_COMMERCE_COUPON_SECTION',
    ServiceOpenSurveySection: 'SECTION_VIEW_TYPE_SERVICE_OPEN_SURVEY_SECTION',
    CreateBusinessProfileAndSuggestPoiButtonSection: 'SECTION_VIEW_TYPE_CREATE_BUSINESS_PROFILE_AND_SUGGEST_POI_BUTTON_SECTION',
    FollowBusinessPostSection: 'SECTION_VIEW_TYPE_FOLLOW_BUSINESS_POST_SECTION',
    SocialCapitalTargetRegionSection: 'SECTION_VIEW_TYPE_SOCIAL_CAPITAL_TARGET_REGION_SECTION',
    SocialCapitalNearbyRegionSection: 'SECTION_VIEW_TYPE_SOCIAL_CAPITAL_NEARBY_REGION_SECTION',
    BusinessThemePostSection: 'SECTION_VIEW_TYPE_BUSINESS_THEME_POST_SECTION',
    NativeRecommendationLocalProfilesRankingSection: 'SECTION_VIEW_TYPE_NATIVE_RECOMMENDATION_LOCAL_PROFILES_RANKING_SECTION',
    ExpertQuestSection: 'SECTION_VIEW_TYPE_EXPERT_QUEST_SECTION',
    LaunchpadSection: 'SECTION_VIEW_TYPE_LAUNCHPAD_SECTION'
} as const;
export type V1SectionViewType = typeof V1SectionViewType[keyof typeof V1SectionViewType];


export function V1SectionViewTypeFromJSON(json: any): V1SectionViewType {
    return V1SectionViewTypeFromJSONTyped(json, false);
}

export function V1SectionViewTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SectionViewType {
    return json as V1SectionViewType;
}

export function V1SectionViewTypeToJSON(value?: V1SectionViewType | null): any {
    return value as any;
}

