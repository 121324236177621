/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { SocialCapitalNearbyRegionSection } from './SocialCapitalNearbyRegionSection';
import {
    SocialCapitalNearbyRegionSectionFromJSON,
    SocialCapitalNearbyRegionSectionFromJSONTyped,
    SocialCapitalNearbyRegionSectionToJSON,
} from './SocialCapitalNearbyRegionSection';
import type { SocialCapitalTargetRegionSection } from './SocialCapitalTargetRegionSection';
import {
    SocialCapitalTargetRegionSectionFromJSON,
    SocialCapitalTargetRegionSectionFromJSONTyped,
    SocialCapitalTargetRegionSectionToJSON,
} from './SocialCapitalTargetRegionSection';
import type { V1CreateBusinessProfileAndSuggestPoiButtonSection } from './V1CreateBusinessProfileAndSuggestPoiButtonSection';
import {
    V1CreateBusinessProfileAndSuggestPoiButtonSectionFromJSON,
    V1CreateBusinessProfileAndSuggestPoiButtonSectionFromJSONTyped,
    V1CreateBusinessProfileAndSuggestPoiButtonSectionToJSON,
} from './V1CreateBusinessProfileAndSuggestPoiButtonSection';
import type { V1ExpertQuestSection } from './V1ExpertQuestSection';
import {
    V1ExpertQuestSectionFromJSON,
    V1ExpertQuestSectionFromJSONTyped,
    V1ExpertQuestSectionToJSON,
} from './V1ExpertQuestSection';
import type { V1FollowBusinessPostSection } from './V1FollowBusinessPostSection';
import {
    V1FollowBusinessPostSectionFromJSON,
    V1FollowBusinessPostSectionFromJSONTyped,
    V1FollowBusinessPostSectionToJSON,
} from './V1FollowBusinessPostSection';
import type { V1LaunchpadSection } from './V1LaunchpadSection';
import {
    V1LaunchpadSectionFromJSON,
    V1LaunchpadSectionFromJSONTyped,
    V1LaunchpadSectionToJSON,
} from './V1LaunchpadSection';
import type { V1LocalStorySection } from './V1LocalStorySection';
import {
    V1LocalStorySectionFromJSON,
    V1LocalStorySectionFromJSONTyped,
    V1LocalStorySectionToJSON,
} from './V1LocalStorySection';
import type { V1NativeRecommendationLocalProfilesRankingSection } from './V1NativeRecommendationLocalProfilesRankingSection';
import {
    V1NativeRecommendationLocalProfilesRankingSectionFromJSON,
    V1NativeRecommendationLocalProfilesRankingSectionFromJSONTyped,
    V1NativeRecommendationLocalProfilesRankingSectionToJSON,
} from './V1NativeRecommendationLocalProfilesRankingSection';
import type { V1PopularCommerceCouponSection } from './V1PopularCommerceCouponSection';
import {
    V1PopularCommerceCouponSectionFromJSON,
    V1PopularCommerceCouponSectionFromJSONTyped,
    V1PopularCommerceCouponSectionToJSON,
} from './V1PopularCommerceCouponSection';
import type { V1PublicInstitutionBusinessPostSection } from './V1PublicInstitutionBusinessPostSection';
import {
    V1PublicInstitutionBusinessPostSectionFromJSON,
    V1PublicInstitutionBusinessPostSectionFromJSONTyped,
    V1PublicInstitutionBusinessPostSectionToJSON,
} from './V1PublicInstitutionBusinessPostSection';
import type { V1RecommendationBusinessPostSection } from './V1RecommendationBusinessPostSection';
import {
    V1RecommendationBusinessPostSectionFromJSON,
    V1RecommendationBusinessPostSectionFromJSONTyped,
    V1RecommendationBusinessPostSectionToJSON,
} from './V1RecommendationBusinessPostSection';
import type { V1SectionViewType } from './V1SectionViewType';
import {
    V1SectionViewTypeFromJSON,
    V1SectionViewTypeFromJSONTyped,
    V1SectionViewTypeToJSON,
} from './V1SectionViewType';
import type { V1ServiceOpenSurveySection } from './V1ServiceOpenSurveySection';
import {
    V1ServiceOpenSurveySectionFromJSON,
    V1ServiceOpenSurveySectionFromJSONTyped,
    V1ServiceOpenSurveySectionToJSON,
} from './V1ServiceOpenSurveySection';
import type { V1ThemeBusinessPostSection } from './V1ThemeBusinessPostSection';
import {
    V1ThemeBusinessPostSectionFromJSON,
    V1ThemeBusinessPostSectionFromJSONTyped,
    V1ThemeBusinessPostSectionToJSON,
} from './V1ThemeBusinessPostSection';

/**
 * 
 * @export
 * @interface V1SectionItem
 */
export interface V1SectionItem {
    /**
     * 
     * @type {V1SectionViewType}
     * @memberof V1SectionItem
     */
    sectionViewType: V1SectionViewType;
    /**
     * 
     * @type {V1LocalStorySection}
     * @memberof V1SectionItem
     */
    localStorySection?: V1LocalStorySection;
    /**
     * 
     * @type {V1RecommendationBusinessPostSection}
     * @memberof V1SectionItem
     */
    recommendationBusinessPostSection?: V1RecommendationBusinessPostSection;
    /**
     * 
     * @type {V1PublicInstitutionBusinessPostSection}
     * @memberof V1SectionItem
     */
    publicInstitutionBusinessPostSection?: V1PublicInstitutionBusinessPostSection;
    /**
     * 
     * @type {V1PopularCommerceCouponSection}
     * @memberof V1SectionItem
     */
    popularCommerceCouponSection?: V1PopularCommerceCouponSection;
    /**
     * 
     * @type {V1ServiceOpenSurveySection}
     * @memberof V1SectionItem
     */
    serviceOpenSurveySection?: V1ServiceOpenSurveySection;
    /**
     * 
     * @type {V1CreateBusinessProfileAndSuggestPoiButtonSection}
     * @memberof V1SectionItem
     */
    createBusinessProfileAndSuggestPoiButtonSection?: V1CreateBusinessProfileAndSuggestPoiButtonSection;
    /**
     * 
     * @type {V1FollowBusinessPostSection}
     * @memberof V1SectionItem
     */
    followBusinessPostSection?: V1FollowBusinessPostSection;
    /**
     * 
     * @type {SocialCapitalTargetRegionSection}
     * @memberof V1SectionItem
     */
    socialCapitalTargetRegionSection?: SocialCapitalTargetRegionSection;
    /**
     * 
     * @type {SocialCapitalNearbyRegionSection}
     * @memberof V1SectionItem
     */
    socialCapitalNearbyRegionSection?: SocialCapitalNearbyRegionSection;
    /**
     * 
     * @type {V1ThemeBusinessPostSection}
     * @memberof V1SectionItem
     */
    themeBusinessPostSection?: V1ThemeBusinessPostSection;
    /**
     * 
     * @type {V1NativeRecommendationLocalProfilesRankingSection}
     * @memberof V1SectionItem
     */
    nativeRecommendationLocalProfilesRankingSection?: V1NativeRecommendationLocalProfilesRankingSection;
    /**
     * 
     * @type {V1ExpertQuestSection}
     * @memberof V1SectionItem
     */
    expertQuestSection?: V1ExpertQuestSection;
    /**
     * 
     * @type {V1LaunchpadSection}
     * @memberof V1SectionItem
     */
    launchpadSection?: V1LaunchpadSection;
}

/**
 * Check if a given object implements the V1SectionItem interface.
 */
export function instanceOfV1SectionItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sectionViewType" in value;

    return isInstance;
}

export function V1SectionItemFromJSON(json: any): V1SectionItem {
    return V1SectionItemFromJSONTyped(json, false);
}

export function V1SectionItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SectionItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sectionViewType': V1SectionViewTypeFromJSON(json['section_view_type']),
        'localStorySection': !exists(json, 'local_story_section') ? undefined : V1LocalStorySectionFromJSON(json['local_story_section']),
        'recommendationBusinessPostSection': !exists(json, 'recommendation_business_post_section') ? undefined : V1RecommendationBusinessPostSectionFromJSON(json['recommendation_business_post_section']),
        'publicInstitutionBusinessPostSection': !exists(json, 'public_institution_business_post_section') ? undefined : V1PublicInstitutionBusinessPostSectionFromJSON(json['public_institution_business_post_section']),
        'popularCommerceCouponSection': !exists(json, 'popular_commerce_coupon_section') ? undefined : V1PopularCommerceCouponSectionFromJSON(json['popular_commerce_coupon_section']),
        'serviceOpenSurveySection': !exists(json, 'service_open_survey_section') ? undefined : V1ServiceOpenSurveySectionFromJSON(json['service_open_survey_section']),
        'createBusinessProfileAndSuggestPoiButtonSection': !exists(json, 'create_business_profile_and_suggest_poi_button_section') ? undefined : V1CreateBusinessProfileAndSuggestPoiButtonSectionFromJSON(json['create_business_profile_and_suggest_poi_button_section']),
        'followBusinessPostSection': !exists(json, 'follow_business_post_section') ? undefined : V1FollowBusinessPostSectionFromJSON(json['follow_business_post_section']),
        'socialCapitalTargetRegionSection': !exists(json, 'social_capital_target_region_section') ? undefined : SocialCapitalTargetRegionSectionFromJSON(json['social_capital_target_region_section']),
        'socialCapitalNearbyRegionSection': !exists(json, 'social_capital_nearby_region_section') ? undefined : SocialCapitalNearbyRegionSectionFromJSON(json['social_capital_nearby_region_section']),
        'themeBusinessPostSection': !exists(json, 'theme_business_post_section') ? undefined : V1ThemeBusinessPostSectionFromJSON(json['theme_business_post_section']),
        'nativeRecommendationLocalProfilesRankingSection': !exists(json, 'native_recommendation_local_profiles_ranking_section') ? undefined : V1NativeRecommendationLocalProfilesRankingSectionFromJSON(json['native_recommendation_local_profiles_ranking_section']),
        'expertQuestSection': !exists(json, 'expert_quest_section') ? undefined : V1ExpertQuestSectionFromJSON(json['expert_quest_section']),
        'launchpadSection': !exists(json, 'launchpad_section') ? undefined : V1LaunchpadSectionFromJSON(json['launchpad_section']),
    };
}

export function V1SectionItemToJSON(value?: V1SectionItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'section_view_type': V1SectionViewTypeToJSON(value.sectionViewType),
        'local_story_section': V1LocalStorySectionToJSON(value.localStorySection),
        'recommendation_business_post_section': V1RecommendationBusinessPostSectionToJSON(value.recommendationBusinessPostSection),
        'public_institution_business_post_section': V1PublicInstitutionBusinessPostSectionToJSON(value.publicInstitutionBusinessPostSection),
        'popular_commerce_coupon_section': V1PopularCommerceCouponSectionToJSON(value.popularCommerceCouponSection),
        'service_open_survey_section': V1ServiceOpenSurveySectionToJSON(value.serviceOpenSurveySection),
        'create_business_profile_and_suggest_poi_button_section': V1CreateBusinessProfileAndSuggestPoiButtonSectionToJSON(value.createBusinessProfileAndSuggestPoiButtonSection),
        'follow_business_post_section': V1FollowBusinessPostSectionToJSON(value.followBusinessPostSection),
        'social_capital_target_region_section': SocialCapitalTargetRegionSectionToJSON(value.socialCapitalTargetRegionSection),
        'social_capital_nearby_region_section': SocialCapitalNearbyRegionSectionToJSON(value.socialCapitalNearbyRegionSection),
        'theme_business_post_section': V1ThemeBusinessPostSectionToJSON(value.themeBusinessPostSection),
        'native_recommendation_local_profiles_ranking_section': V1NativeRecommendationLocalProfilesRankingSectionToJSON(value.nativeRecommendationLocalProfilesRankingSection),
        'expert_quest_section': V1ExpertQuestSectionToJSON(value.expertQuestSection),
        'launchpad_section': V1LaunchpadSectionToJSON(value.launchpadSection),
    };
}

