import { SnackbarProvider } from '@daangn/sprout-components-snackbar'
import { ImpressionRoot } from '@daangn/web-impression'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useCallback } from 'react'

import { IS_DEBUG_APP, IS_LOCAL, IS_PRODUCTION, STAGE } from '@src/constants'
import { type PropOf } from '@src/ts-utils/types/utilTypes'

import AppLoading from './AppLoading'
import { karrotBridge } from './bridge/bridge'
import { BridgeInfoProvider } from './bridge/context/BridgeInfoProvider'
import {
  type BridgeGeolocation,
  GeolocationProvider,
} from './bridge/context/GeolocationProvider'
import { IS_E2E } from './bridge/searchPramaInsertion'
import withSuspense from './components/suspense/withSuspense'
import { useExperimentStore } from './experiment/experimentStore'
import { gerFrontDrivenExperiments } from './experiment/frontDrivenExperiment'
import { getServerDrivenExperiments } from './experiment/serverDrivenExperiment'
import { getStorageMergedExperiments } from './experiment/storage'
import { GlobalEventBusProvider } from './GlobalEventBus'
import { useBackButtonEnabled } from './hooks/useBackRouter'
import { AppThemeProvider } from './hooks/useTheme'
import DevtoolsRoot from './local-map-utils/dev-tools/DevtoolsRoot'
import { RefetchFailedApiView } from './local-map-utils/error-report/RefetchFailedApiPageComponent'
import { withGeneralErrorBoundary } from './local-map-utils/error-report/withGeneralErrorBoundary'
import { checkKarrotError } from './local-map-utils/karrotError/parseKarrotError'
import { setSafeAreaValues } from './local-map-utils/safeAreaVariable'
import { karrotBridgeLog } from './log/bridgeLog'
import { type LocalMapEventName } from './log/eventNames/types'
import { isExternalReferrerName } from './log/eventNames/validatation'
import { coordinatesStore } from './pages/home-feed-step/_store/coordinatesStore'
import LocalMapUserLocationProvider from './provider/LocalMapUserLocationProvider'
import { Stack } from './stackflow'
import { initializeExternalAccessValues } from './store/externalAccessValues'
import { setTools } from './third-party/setTools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
    },
  },
})

initializeExternalAccessValues({
  onInialize: ({ referrer }) => {
    // debuggerApp일 때만 실행
    if (!IS_DEBUG_APP) {
      return
    }

    // referrer가 있는지도 체크해야될까
    if (!!referrer && !isExternalReferrerName(referrer)) {
      console.warn(`[externalAccessValues] referrer is invalid: ${referrer}`)
      karrotBridge.openToast({
        toast: {
          body: `referrer가 동네지도 정책과 일치하지않는 referrer예요. 동네지도 팀에 알려주세요 ${referrer}`,
        },
      })
    }
  },
})

const App = () => {
  setSafeAreaValues()
  useBackButtonEnabled()
  const setDistancedCoordinates = coordinatesStore(
    (s) => s.setDistancedCoordinates
  )

  const handleBridgeInfoLoaded: PropOf<
    typeof BridgeInfoProvider,
    'onBridgeInfoLoaded'
  > = useCallback(async (bridgeInfo) => {
    // datadog rum, sentry, clarity 설정
    if (STAGE !== 'development' || !IS_E2E) {
      setTools(bridgeInfo)
    }

    const serverDrivenExperiment = await getServerDrivenExperiments(bridgeInfo)
    const frontDrivenExperiment = gerFrontDrivenExperiments({
      userId: bridgeInfo.user.id,
    })

    const storageMergedExperiments = getStorageMergedExperiments({
      ...serverDrivenExperiment,
      ...frontDrivenExperiment,
    })

    useExperimentStore.getState().setExperiments(storageMergedExperiments)
  }, [])

  const handleGeolocationLoaded = useCallback(
    (geolocation: BridgeGeolocation) => {
      if (!geolocation.currentPosition) {
        return
      }

      setDistancedCoordinates({
        coordinates: {
          latitude: geolocation.currentPosition?.latitude,
          longitude: geolocation.currentPosition?.longitude,
        },
        type: 'currentPosition',
      })
    },
    [setDistancedCoordinates]
  )

  return (
    <BridgeInfoProvider onBridgeInfoLoaded={handleBridgeInfoLoaded}>
      <GeolocationProvider onGeologationLoaded={handleGeolocationLoaded}>
        <SnackbarProvider offset="0.75rem">
          <QueryClientProvider client={queryClient}>
            <LocalMapUserLocationProvider>
              <GlobalEventBusProvider>
                <AppThemeProvider>
                  <ImpressionRoot
                    development={IS_LOCAL}
                    impressionOptions={{
                      threshold: 0.75,
                      coolTime: 60000,
                      exposureTime: 1000,
                    }}
                    logger={(logParams) => {
                      karrotBridgeLog(
                        logParams.name as LocalMapEventName,
                        logParams.params
                      )
                    }}
                    initialized={true}
                  >
                    <Stack />

                    {
                      /* 프로덕션이 아니고 네이티브 디버거 앱에만 노출  */
                      (!IS_PRODUCTION || IS_DEBUG_APP || IS_LOCAL) && (
                        <DevtoolsRoot />
                      )
                    }
                  </ImpressionRoot>
                </AppThemeProvider>
              </GlobalEventBusProvider>
            </LocalMapUserLocationProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </GeolocationProvider>
    </BridgeInfoProvider>
  )
}

export default withGeneralErrorBoundary(
  withSuspense(App, AppLoading),
  ({ error }) => {
    const infraError = error.cause as {
      status: number
      serverResponse: object
    } | null

    const inspectionErrMsg = (() => {
      // 상태가 599면 점검 애러
      if (infraError && infraError.status === 599) {
        const { karrotErrorData } = checkKarrotError(
          (error.cause as { serverResponse?: object })?.serverResponse
        )

        return karrotErrorData?.message
      }
    })()

    return (
      <div style={{ height: '100vh' }}>
        <RefetchFailedApiView
          message={
            inspectionErrMsg ??
            '알 수 없는 오류가 발생했어요\n인터넷 연결을 확인해주세요'
          }
          onRefetchClick={() => {
            window.location.href = `${window.location.origin}/home`
          }}
        />
      </div>
    )
  },
  {
    isCritical: true,
  }
)
